<template>
  <div class="c-navigation">
    <router-link v-for="route in routes" :key="route.path"  :to="route.path">{{ route.name }}</router-link>
  </div>
</template>

<script>

import { routes } from '../router';

export default {
  name: 'DevNavigation',
  data () {
    return {
      routes
    };
  }
};

</script>

<style lang="scss" scoped>
.c-navigation {
  position: fixed;
  top: 16px;
  left: 50vw;
  text-align: center;
  width: 600px;
  transform: translateX(-50%);
  background-color: rgba(0,0,0,.1);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  a {
    padding: 1em;
  }
}
</style>
