/* eslint-disable */
import Oidc from 'oidc-client';

var mgr = new Oidc.UserManager({
  automaticSilentRenew: true,
  client_id: process.env.VUE_APP_CLIENT_ID,
  silent_redirect_uri: process.env.VUE_APP_URL+'/silent-renew.html',
  authority: process.env.VUE_APP_AUTHORITY,
  client_secret: process.env.VUE_APP_SECRET,
  redirect_uri: process.env.VUE_APP_URL+"/signin-oidc.html",
  post_logout_redirect_uri: process.env.VUE_APP_URL+"/logged-out",
  response_type: "id_token token",
  scope: "openid IdentityServerApi profile",
  filterProtocolClaims: true,
  // response_mode: 'form_post',
  loadUserInfo: true,
})
// console.log('MGR: ',mgr);

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.INFO;

/**
 * When logged in the events will trigger by them selfes
 * see @link https://github.com/IdentityModel/oidc-client-js/wiki#events
 */
// mgr.events.addUserLoaded(function (user) {  
//   console.log('New User Loaded：', arguments);
//   console.log('Acess_token: ', user.access_token)
// });

// mgr.events.addAccessTokenExpiring(function () {
//   console.log('AccessToken Expiring：', arguments);
// });

// mgr.events.addAccessTokenExpired(function () {
//   console.log('AccessToken Expired：', arguments);  
//   alert('Session expired. Going out!');
//   mgr.signoutRedirect().then(function (resp) {
//     console.log('signed out', resp);
//   }).catch(function (err) {
//     console.log(err)
//   })
// });

// mgr.events.addSilentRenewError(function () {
//   console.error('Silent Renew Error：', arguments);
// });

// mgr.events.addUserSignedOut(function () {
//   alert('Going out!');
//   console.log('UserSignedOut：', arguments);  
//   mgr.signoutRedirect().then(function (resp) {
//     console.log('signed out', resp);
//   }).catch(function (err) {
//     console.log(err)
//   })
// });

export default class SecurityService {

  // Renew the token manually
  renewToken () {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.signinSilent().then(function (user) {
        if (user == null) {
          self.signIn(null)
        } else{
          return resolve(user)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Get the user who is logged in
  getUser () {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else{          
          return resolve(user)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Check if there is any user logged in
  getSignedIn () {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn()
          return resolve(false)
        } else{
          return resolve(true)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Redirect of the current window to the authorization endpoint.
  signIn () {
    mgr.signinRedirect().catch(function (err) {
      console.log(err)
    })
  }
  
  // Redirect of the current window to the end session endpoint
  signOut () {
    mgr.signoutRedirect().then(function (resp) {
      console.log('signed out', resp);
    }).catch(function (err) {
      console.log(err)
    })
  }

  // Get the profile of the user logged in
  getProfile () {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else{
          return resolve(user.profile)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Get the token id
  getIdToken(){
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else{
          return resolve(user.id_token)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Get the session state
  getSessionState(){
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else{          
          return resolve(user.session_state)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Get the access token of the logged in user
  getAcessToken(){
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else{
          return resolve(user.access_token)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Takes the scopes of the logged in user
  getScopes(){
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else{
          return resolve(user.scopes)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Get the user roles logged in
  getRole () {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else{          
          return resolve(user.profile.role)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }
  
}