import axios from 'axios';

export class MapaDeAtoresRepository {
  static async getAll () {
    try {
      const getListResponse = await axios.get('/api/mapa-de-atores', {
        params: {}
      });
      return getListResponse.data.data;
    } catch (error) {
      return [];
    }
  }

  static async getLigacoes () {
    try {
      const getListResponse = await axios.get('/api/mapa-de-atores-ligacao', {
        params: {}
      });
      return getListResponse.data.data;
    } catch (error) {
      return [];
    }
  }

  static async getNomeLigacoes () {
    try {
      const getListResponse = await axios.get('/api/mapa-de-atores-nome-ligacao', {
        params: {}
      });
      return getListResponse.data.data;
    } catch (error) {
      return [];
    }
  }

  static async getLigacaoParcerias () {
    try {
      const getListResponse = await axios.get('/api/mapa-de-atores-ligacao-parcerias', {
        params: {}
      });
      return getListResponse.data.data;
    } catch (error) {
      return [];
    }
  }

  static async getLigacaoPlanoDeAcao () {
    try {
      const getListResponse = await axios.get('/api/mapa-de-atores-ligacao-plano-de-acao', {
        params: {}
      });
      return getListResponse.data.data;
    } catch (error) {
      return [];
    }
  }

  static async getAllPlanos () {
    try {
      const getListResponse = await axios.get('/api/mapa-de-atores-plano-de-acao', {
        params: {}
      });
      return getListResponse.data.data;
    } catch (error) {
      return [];
    }
  }
}

export default MapaDeAtoresRepository;
