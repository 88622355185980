import axios from 'axios';

export class AtoresRepository {
  /**
   *
   * @param {object} model
   * @returns {Promise<{ data?: any, error?: any }> }
   */
  static async add (model) {
    try {
      const response = await axios.post('/api/atores', model);
      return { data: response.data };
    } catch (error) {
      return { error };
    }
  }

  /**
   * @param {number} id
   * @param {object} model
   * @returns {Promise<{ data?: any, error?: any }> }
   */
  static async update (id, model) {
    try {
      const response = await axios.put(`/api/atores/${id}`, model);
      return { data: response.data };
    } catch (error) {
      return { error };
    }
  }

  /**
   * @param {object} param0
   * @param {number} param0.page mínimo 1
   * @param {number} param0.perPage mínimo 1 máximo 50
   * @param {'id'|'nome_do_ator'} param0.sortBy Por qual campo deve ordenar, apenas 'id' e 'nome_do_ator' permitidos por hora
   * @param {'asc'|'desc'} param0.direction Sentido da ordenação 'asc' de ascendente e 'desc' descendente
   * @param {'ambos'|'fisico'|'juridico'} param0.actorType Filtro que indica o tipo dos atores que devem ser buscados
   *
   * @returns {Promise<object[]>}
   */
  static async getAll ({ page, perPage, sortBy, direction, actorType }) {
    // @TODO implementar filtros, paginação, orderBy e checar se o get dá conta
    try {
      const getListResponse = await axios.get('/api/atores', {
        params: {
          page,
          perPage,
          sortBy,
          direction,
          actorType
        }
      });

      return getListResponse.data;
    } catch (error) {
      return [];
    }
  }

  /**
   *
   * @param {number} id
   * @returns {Promise<object|null>}
   */
  static async getById (id) {
    try {
      const getByIdResponse = await axios.get(`/api/atores/${id}`);

      return getByIdResponse.data;
    } catch (error) {
      return null;
    }
  }

  /**
   *
   * @param {number} id
   * @returns {Promise<boolean>}
   */
  static async deleteById (id) {
    try {
      await axios.delete(`/api/atores/${id}`);

      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   *
   * @param {object} param0
   * @param {string} param0.nameHint Trecho do nome do ator que estamos procurando
   * @param {number[]} [param0.excludedIds] Lista com os ids que podem ser excluídos das sugestões
   * @returns {Promise<{ data?: any[], error?: any }> }
   */
  static async searchByName ({ nameHint, excludedIds }) {
    try {
      const response = await axios.post('/api/atores/procura-por-nome', { nameHint, excludedIds });
      return { data: response.data };
    } catch (error) {
      return { error };
    }
  }

  static async searchActorByParceria (id) {
    try {
      const searchActorByParceria = await axios.get(`/api/livre/parceria/${id}`);
      return searchActorByParceria.data;
    } catch (error) {
      return false;
    }
  }

  static async removeActorByParceria ({
    atorId,
    parceriaId
  }) {
    try {
      return await axios.post('/api/livre/remover-parceria', {
        atorId,
        parceriaId
      });
    } catch (error) {
      return { error: error };
    }
  }

  static async removeActorByPlano ({
    atorId,
    planoId
  }) {
    try {
      return await axios.post('/api/livre/remover-plano', {
        atorId,
        planoId
      });
    } catch (error) {
      return { error: error };
    }
  }

  static async searchActorByPlanosAcao (id) {
    try {
      const searchActorByPlanosAcao = await axios.get(`/api/livre/planos-acao/${id}`);
      return searchActorByPlanosAcao.data;
    } catch (error) {
      return false;
    }
  }

  static async searchByCpf (cpf) {
    try {
      const response = await axios.post('/api/atores/procura-por-cpf', { cpf });
      return { data: response.data };
    } catch (error) {
      return { error };
    }
  }

  static async searchByEmail (email) {
    try {
      const response = await axios.post('/api/atores/procura-por-email', { email });
      return { data: response.data };
    } catch (error) {
      return { error };
    }
  }
}

export default AtoresRepository;
