import axios from 'axios';

export class AssociacoesRepository {
  /**
   * @param {object} param0
   *
   * @returns {Promise<object>}
   */
  static async sync ({
    deAtorId,
    criarId,
    criarTipoId,
    desfazerId,
    desfazerTipoId
  }) {
    try {
      return await axios.post('/api/associacoes', {
        deAtorId,
        criarId,
        criarTipoId,
        desfazerId,
        desfazerTipoId
      });
    } catch (error) {
      // console.log(criarId);
      return { error: error };
    }
  }
}
