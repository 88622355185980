import axios from 'axios';

export class GetTipoAssociacao {
  static async getAll () {
    try {
      const getListResponse = await axios.post('/api/getassociacoes', {});

      return getListResponse.data;
    } catch (error) {
      return [];
    }
  }
}
