import axios from 'axios';
import Auth from './Auth';
import { createApp } from 'vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import VNetworkGraph from 'v-network-graph';
import 'v-network-graph/lib/style.css';

// @ts-expect-error
import App from './App.vue';

/**
 * Ideia de como configurar
 * https://stackoverflow.com/questions/65547199/using-bootstrap-5-with-vue-3#answer-65555991
 */
import 'bootstrap';

if (document.location.pathname.includes('signin-oidc')) {
  alert('contains');
  // document.location.href = '/signin-oidc.html' + document.location.search
}

/**
 * Configuração do axios para requisitar troca de credenciais para o correto funcionamento
 *
 * @note https://laravel.com/docs/8.x/sanctum#cors-and-cookies
 */
axios.defaults.withCredentials = true;

// const auth = new Auth();
// auth.getAcessToken().then(token => {
//   axios.defaults.headers.common.Authorization = `Bearer ${token}`;
// })

// if (localStorage.getItem('token')) {
//   const token = localStorage.getItem('token');
//   axios.defaults.headers.common.Authorization = `Bearer ${token}`;
// }

createApp(App).use(store).use(VNetworkGraph).use(router).mount('#app');
