import { createRouter, createWebHistory } from 'vue-router';

export const routes = [
  // Redirect pra facilitar o acesso a tela de login
  {
    path: '/',
    redirect: { name: 'Dashboard' },
    component: null
  },
  {
    path: '/login',
    name: 'Login',
    // @ts-expect-error
    component: () => import('../views/Login.vue')
  },
  {
    path: '/signin-oidc',
    name: 'singIn',
    component: null,
    redirect: { name: 'Dashboard' }
  },
  {
    path: '/admin/',
    // @ts-expect-error
    component: () => import('../views/Layout.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        // @ts-expect-error
        component: () => import('../views/Dashboard.vue')
      },
      {
        path: 'conexoes',
        name: 'Mapa.Conexoes',
        // @ts-expect-error
        component: () => import('../views/mapaDeConexoes/MapaDeConexoes.vue')
      },
      /**
       * ROTAS DE PARCERIAS
       */
      {
        path: 'parcerias',
        name: 'Parcerias',
        // @ts-expect-error
        component: () => import('../views/parcerias/ParceriasListagem.vue')
      },
      {
        path: 'parcerias/historico',
        name: 'ParceriasHistorico',
        // @ts-expect-error
        component: () => import('../views/parcerias/ParceriasListagem.vue')
      },
      {
        path: 'parcerias/cadastro',
        name: 'Parcerias.Cadastro',
        // @ts-expect-error
        component: () => import('../views/parcerias/ParceriasCadastro.vue')
      },
      {
        path: 'parcerias/edicao/:id',
        name: 'Parcerias.Edicao',
        // @ts-expect-error
        component: () => import('../views/parcerias/ParceriasEdicao.vue')
      },
      {
        path: 'parcerias/visualizacao/:id',
        name: 'Parcerias.Visualizacao',
        // @ts-expect-error
        component: () => import('../views/parcerias/ParceriasVisualizacao.vue')
      },
      /**
       * ROTAS DE GESTÃO DE ATORES
       */
      {
        path: 'atores',
        name: 'Atores',
        // @ts-expect-error
        component: () => import('../views/atores/AtoresListagem.vue')
      },
      {
        path: 'atores/cadastro',
        name: 'Atores.Cadastro',
        // @ts-expect-error
        component: () => import('../views/atores/AtoresCadastro.vue')
      },
      {
        path: 'atores/edicao/:id',
        name: 'Atores.Edicao',
        // @ts-expect-error
        component: () => import('../views/atores/AtoresEdicao.vue')
      },
      {
        path: 'atores/visualizacao/:id',
        name: 'Atores.Visualizacao',
        // @ts-expect-error
        component: () => import('../views/atores/AtoresVisualizacao.vue')
      },
      /* ROTA DE ASSOCIAÇÃO LIVRE DO ATOR */
      {
        path: 'atores/associacao-livre/visualizacao/:id',
        name: 'Livre.Visualizacao',
        // @ts-expect-error
        component: () => import('../views/associacaoLivre/AssociacaoLivreTemplate.vue')
      },
      {
        path: 'atores/associacao-livre/edicao/:id',
        name: 'Livre.Edicao',
        // @ts-expect-error
        component: () => import('../views/associacaoLivre/AssociacaoLivreTemplate.vue')
      },
      {
        path: 'atores/associacao-livre/cadastro/:id',
        name: 'Livre.Cadastro',
        // @ts-expect-error
        component: () => import('../views/associacaoLivre/AssociacaoLivreTemplate.vue')
      },
      /**
       * ROTAS DE GESTÃO DE PLANOS DE AÇÃO
       */
      {
        path: 'planos-de-acao',
        name: 'PlanosDeAcao',
        // @ts-expect-error
        component: () => import('../views/planosDeAcao/PlanosDeAcaoListagem.vue')
      },
      {
        path: 'planos-de-acao/historico',
        name: 'PlanosDeAcaoHistorico',
        // @ts-expect-error
        component: () => import('../views/planosDeAcao/PlanosDeAcaoListagem.vue')
      },
      {
        path: 'planos-de-acao/cadastro',
        name: 'PlanosDeAcao.Cadastro',
        // @ts-expect-error
        component: () => import('../views/planosDeAcao/PlanosDeAcaoCadastro.vue')
      },
      {
        path: 'planos-de-acao/edicao/:id',
        name: 'PlanosDeAcao.Edicao',
        // @ts-expect-error
        component: () => import('../views/planosDeAcao/PlanosDeAcaoEdicao.vue')
      },
      {
        path: 'planos-de-acao/visualizacao/:id',
        name: 'PlanosDeAcao.Visualizacao',
        // @ts-expect-error
        component: () => import('../views/planosDeAcao/PlanosDeAcaoVisualizacao.vue')
      },

      /** ROTAS DO MEU PERFIL **/
      {
        path: 'meu-perfil/visualizacao/:id',
        name: 'MeuPerfil.Visualizacao',
        // @ts-expect-error
        component: () => import('../views/meuPerfil/MeuPerfilVisualizacao.vue')
      },
      // Rota provisória )
      {
        path: 'onboarding',
        name: 'Onboarding',
        // @ts-expect-error
        component: () => import('../components/OnboardingLayout.vue')
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// router.beforeEach((to, from, next) => {
//   const logado = !!localStorage.getItem('token');
//   // @TODO temporário para testes
//   if (to.name !== 'Login' && !logado) {
//     next({ name: 'Login' });
//   } else if (to.name === 'Login' && logado) {
//     next({ name: 'Dashboard' });
//   } else {
//     next();
//   }
// });

export default router;
