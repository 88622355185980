import axios from 'axios';

export class PlanosDeAcaoRepository {
  /**
   * @param {object} model
   * @returns {Promise<boolean>}
   */
  static async add (model) {
    try {
      await axios.post('/api/planos-de-acao', model);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * @param {number} id
   * @param {object} model
   * @returns {Promise<boolean>}
   */
  static async update (id, model) {
    try {
      await axios.put(`/api/planos-de-acao/${id}`, model);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * @returns {Promise<object[]>}
   */
  static async getAll () {
    try {
      const getListResponse = await axios.get('/api/planos-de-acao', {});

      return getListResponse.data;
    } catch (error) {
      return [];
    }
  }

  /**
   *
   * @param {number} id
   * @returns {Promise<object|null>}
   */
  static async getById (id) {
    try {
      const getByIdResponse = await axios.get(`/api/planos-de-acao/${id}`);

      return getByIdResponse.data;
    } catch (error) {
      return null;
    }
  }

  /**
   *
   * @param {number} id
   * @returns {Promise<boolean>}
   */
  static async deleteById (id) {
    try {
      await axios.delete(`/api/planos-de-acao/${id}`);

      return true;
    } catch (error) {
      return false;
    }
  }
}

export default PlanosDeAcaoRepository;
