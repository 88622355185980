<template>
  <!-- Menu de navegação -->
  <DevNavigation v-show="false" />

  <router-view/>

  <Modal v-show="false" />

</template>

<script>
/*eslint-disable */
import Modal from './components/Modal.vue';
import DevNavigation from './components/DevNavigation.vue';
import Api from './Auth';
import axios from 'axios';
import { AtoresRepository } from './libs/repositories';

export default {
  name: 'App',
  components: {
    DevNavigation,
    Modal
  },
  async created () {
    const api = new Api();
    const token = await api.getAcessToken();
    const tokenId = await api.getIdToken();
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;

    axios.defaults.headers.common['Custom-Authorization'] = 'Bearer ' + tokenId 

    axios.get('/api/').then(resp => {
      console.log('middleware ok');
    });
    api.getUser();
    // api.getScopes();
    // api.getProfile();
    const perfil = await api.getProfile();
    const atoresCpf = await AtoresRepository.searchByCpf(perfil.Cpf);
    let id = '';
    
    if(atoresCpf.data.length === 0){
      // console.log('primeiro if');
      const atoresEmail = await AtoresRepository.searchByEmail(perfil.email);
      if(atoresEmail.data.length === 0){
        this.$router.push({ name: 'Atores.Cadastro', params: { email: perfil.email }})
      } else {
        id = atoresEmail.data[0].id;
        this.$router.push({ name: this.$route.name, params: { id: id }})
        this.$store.state.idUsuario = atoresEmail.data[0].id;
      }
    } else{
      id = atoresCpf.data[0].id;
      this.$router.push({ name: this.$route.name, params: { id: id }})
      this.$store.state.idUsuario = atoresCpf.data[0].id;
    }
  }
};

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,500;0,700;1,400&display=swap');
html, body {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: 'Open Sans',Avenir,Helvetica, Arial, sans-serif !important;
  // font-weight: 500 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  line-height: 1.1;
  padding: 0;
  margin: 0;
  background-color: #F7F7FA !important;
}

.app-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.form-custom{
  -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
}

/**
 * Ideia de como configurar
 * https://stackoverflow.com/questions/65547199/using-bootstrap-5-with-vue-3#answer-65555991
 */
// Customizando a cor primária
$primary: #055B94 !default;

@import "~bootstrap/scss/bootstrap.scss";
</style>
